.modal {
  z-index: 20;
  position: absolute;
  border-radius: 1rem;
}

.modal-xs {
  top: 33%;
  left: calc((100% - 360px) / 2);
  width: 360px;
  max-width: 360px;
}

.modal-sm {
  top: 33%;
  left: calc((100% - 480px) / 2);
  width: 480px;
  max-width: 480px;
}

.modal-md {
  top: 62px;
  left: calc((100% - 640px) / 2);
  width: 640px;
  max-height: 640px;
}

.modal-lg {
  top: 62px;
  left: calc((100% - 1080px) / 2);
  width: 1080px;
}

@media (max-width: 1080px) {
  .modal-lg {
    left: 0%;
    width: 100%;
    border-radius: 0px;
  }
}

@media (max-width: 768px) {
  .modal-sm {
    left: calc((100% - 360px) / 2);
    width: 360px;
    max-width: 360px;
  }

  .modal-md {
    left: 0%;
    width: 100%;
    border-radius: 0px;
  }
}

@media (max-width: 360px) {
  .modal-xs {
    left: 0px;
    border-radius: 0px;
  }

  .modal-sm {
    left: 0px;
    border-radius: 0px;
  }
}
