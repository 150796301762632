.data-table {
  position: relative;
  clear: both;
  zoom: 1;
  border-collapse: collapse;
}

.data-table .data-table-body {
  border-bottom: 1px solid #111;
  border-top: 1px solid #111;
}

.data-table td,
th {
  display: table-cell;
  padding: 4px 8px !important;
  white-space: nowrap !important;
}

tr {
  border-top: 1px solid #ddd;
}

tr:first-child {
  border: none;
}
